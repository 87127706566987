<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CTabs fade addTabsWrapperClasses="tab-info">
          <CTab :title="$t('Product/Detail.StockInfo')" active>
            <CRow>
              <CCol md="8">
                <CInput
                  :label="$t('Product/Detail.SKUName')"
                  v-model="Data.Name"
                  horizontal
                />
                <CInput
                  :label="$t('Product/Detail.Stock/SKU')"
                  v-model="Data.CustomSeriesNum"
                  horizontal
                />
                <CInput
                  v-model="Data.Stock.RealStock"
                  type="number"
                  disabled="true"
                  horizontal
                >
                  <template #label>
                    <label class="col-form-label col-sm-3">
                      {{ $t('Product/Detail.Stock/RealStock') }} <i v-c-tooltip="{content: $t('Product/Detail.Stock/RealStockDisabled'),placement: 'bottom'}" class="fas fa-question-circle text-info ml-1" />
                    </label>
                  </template>
                </CInput>
                <CInput
                  :label="$t('Product/Detail.Stock/SafeStock')"
                  v-model="Data.Stock.SafeStock"
                  type="number"
                  horizontal>
                  <template #label>
                    <label class="col-form-label col-sm-3">
                      {{ $t('Product/Detail.Stock/SafeStock') }} <i v-c-tooltip="{content: $t('Product/Detail.Stock/SafeStockDescription'),placement: 'bottom'}" class="fas fa-question-circle text-info ml-1" />
                    </label>
                  </template>
                </CInput>
                <CInput
                  :prepend="$store.state.currency + '$'"
                  :label="$t('Product/Detail.Amount/Cost')"
                  v-model="Data.Amount.Cost"
                  type="number"
                  min="0.0000" step="0.0001"
                  horizontal />
                <CInput
                  :label="$t('Product/Detail.Model')"
                  v-model="Data.Model"
                  horizontal
                />
              </CCol>
              <CCol md="4">
                <CInput
                  :label="$t('Product/Detail.Weight')"
                  v-model="Data.Variable.Weight"
                  type="number"
                  min="0.0000" step="0.0001"
                  horizontal>
                  <template #append-content>
                    <span>KG</span>
                  </template>
                </CInput>
                <CInput
                  :label="$t('Product/Detail.NetWeight')"
                  v-model="Data.Variable.NetWeight"
                  type="number"
                  min="0.0000" step="0.0001"
                  horizontal>
                  <template #append-content>
                    <span>KG</span>
                  </template>
                </CInput>
                <CInput
                  :label="$t('Product/Detail.Length')"
                  v-model="Data.Variable.Length"
                  type="number"
                  min="0.00" step="0.01"
                  horizontal>
                  <template #append-content>
                    <span>CM</span>
                  </template>
                </CInput>
                <CInput
                  :label="$t('Product/Detail.Width')"
                  v-model="Data.Variable.Width"
                  type="number"
                  min="0.00" step="0.01"
                  horizontal>
                  <template #append-content>
                    <span>CM</span>
                  </template>
                </CInput>
                <CInput
                  :label="$t('Product/Detail.Height')"
                  v-model="Data.Variable.Height"
                  type="number"
                  min="0.00" step="0.01"
                  horizontal>
                  <template #append-content>
                    <span>CM</span>
                  </template>
                </CInput>
                <CSelect horizontal :label="$t('Product/Detail.Temperature')" :options="TemperatureOptions"
                         v-model="Data.Variable.Temperature" :value.sync="Data.Variable.Temperature"
                         :placeholder="$t('Global.PleaseSelect')"
                         :description="$t('Product/Detail.TemperatureDescription')"
                />
              </CCol>
            </CRow>
          </CTab>
          <CTab :title="$t('Product/Detail.Element')" disabled>
            <template #title>
              {{ $t('Product/Detail.Contents') }} <CBadge class="ml-2" color="info" v-c-tooltip="{content: $t('Global.PreviewDescription'), placement: 'bottom'}">{{ $t('Global.Preview') }}</CBadge>
            </template>
          </CTab>
        </CTabs>
        <CCard class="rounded-0">
          <CCardBody class="py-2 px-3">
            <div class="card-header-actions d-flex">
              <CButton color="success" type="submit">
                {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
              </CButton>
            </div>
          </CCardBody>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
  </section>
</template>

<route>
{
"meta": {
"label": "編輯貨品"
}
}
</route>

<script>
import dayjs from 'dayjs'
import DataSchema from '@/schema/product/detail'

export default {
  name: 'ProductStockDetail',
  layout: 'manage',
  data () {
    return {
      Action: 'Edit',
      Data: {}, //主資料

      ProductStatus: true, //格式化後的商品狀態
      EnableStock: true, //格式化後的商品庫存啟用開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      Debug: false,
      CategoryList: [],
      VariableList: [],
      NowChooseDevice: 'Web',
      NowChooseLanguage: '',
      Loading: false,
      Drag: false,
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false,
        ChooseImageType: '',
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {}
      },
      TypeList: ['boolean', 'string', 'number', 'object', 'array'],
      VariableNoItems: {
        noResults: this.$t('Product/Detail.NoVariableItems'),
        noItems: this.$t('Product/Detail.NoVariableItems')
      }
    }
  },
  computed: {
    TemperatureOptions() {
      return Object.keys(this.$t('Logistics.TemperatureOptions')).map(value => {
        return {
          label: this.$t('Logistics.TemperatureOptions.' + value),
          value
        }
      })
    }
  },
  created() {
    this.$set(this.$data, 'Data', Object.assign({}, DataSchema))
    this.Action = (this.$route.name === 'products-detail-stock-add' ? 'Add' : 'Edit')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const Functions = []
      if (this.Action === 'Edit') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        //詳細資料處理
        if (this.Action === 'Edit') this.HandleDetailData()
        return true
      }).catch((err) => {
        console.error(err)
        throw err
      })
    },
    Save() {
      //拆分動作
      let Action
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Product/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/products/stock/detail/' + result.data.SeriesNum)
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Product/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Product/SlugEmpty')
        }
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/add',
        method: 'post',
        data: {
          ...this.Data,
          Stock: {
            ...this.Data.Stock,
            SKU: this.Data.CustomSeriesNum
          },
          Taxonomy: 'Stock',
          Status: this.ProductStatus ? 1 : 0,
          EnableStock: this.EnableStock ? 1 : 0,
          EnableResponseContent: this.EnableResponseContent ? 1 : 0,
          OnlineTime: 0,
          OfflineTime: 2147483647,
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Product/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        throw {
          code: 403,
          msg: this.$t('Message.Product/SlugEmpty')
        }
      }
      const updateData = {
        ...this.Data,
        Stock: {
          ...this.Data.Stock,
          SKU: this.Data.CustomSeriesNum
        },
        Status: this.ProductStatus ? 1 : 0,
        EnableStock: this.EnableStock ? 1 : 0,
        EnableResponseContent: this.EnableResponseContent ? 1 : 0,
        OnlineTime: dayjs(this.Data.OnlineTime).unix(),
        OfflineTime: dayjs(this.Data.OfflineTime).unix()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/edit',
        method: 'post',
        data: {
          seriesNum: updateData.SeriesNum,
          taxonomy: 'Stock',
          updateData
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Product/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/stock/detail',
        method: 'get',
        params: {
          seriesNum: this.$route.params.id,
          taxonomy: 'Stock'
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
        if (!this.Data.Variable) this.$set(this.Data, 'Variable', {})
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    HandleDetailData() {
      if (this.Data.Variable) {
        this.VariableList = Object.keys(this.Data.Variable).map((data) => {
          let Value,
            Type = typeof this.Data.Variable[data]
          switch (typeof this.Data.Variable[data]) {
            case 'boolean':
            case 'string':
            case 'undefined':
            case 'number':
              Value = this.Data.Variable[data]
              break
            case 'object':
              Value = JSON.stringify(this.Data.Variable[data])
              if (Array.isArray(this.Data.Variable[data])) {
                Type = 'array'
              }
              break
          }
          return {
            Index: data,
            Value,
            Type
          }
        })
      }

      this.ProductStatus = (this.Data.Status === 1)
      this.EnableOption = (this.Data.EnableOption === 1)
      this.EnableStock = (this.Data.EnableStock === 1)
      this.HiddenAmount = (this.Data.HiddenAmount === 1)
      this.EnableResponseContent = (this.Data.EnableResponseContent === 1)
    },
    HandleVariable() {
      this.VariableList.forEach((data) => {
        this.Data.Variable[data.Index] = data.Value
      })
    },
    SetMeta() {
      this.MediaMetaConfig.ImageMeta = {}
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.MediaMetaConfig.Display = false
    },
    RemoveImage(type = '', index) {
      this.Data[type].splice(index, 1)
    },
  }
}
</script>
